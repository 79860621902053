<template>
    <div class="loginBg">
        <div id="main">
            <div class="container">
                <div class="title wow animated fadeInDown">{{$t('login.loginTitle')}}</div>

                <div class="form_box wow animated fadeIn">
                    <div class="inp_label">{{$t('common.userName')}}</div>
                    <input v-model="account" class="inp" type="text" data-index="username" :placeholder="$t('tip.usernameEmpty')" />

                    <div class="inp_label">
                        {{$t('common.pwd')}}
                        <span class="gradient g_btn" @click="jump('/forget')">{{$t('login.forget')}}</span>
                    </div>

                    <input v-model="password" class="inp" type="password" data-index="password" :placeholder="$t('tip.pwdEmpty1')" />
                    
                    <div class="fd">
                        <label for="readme">
                            <input v-model="checked" type="checkbox" name="readme" id="readme" class="checkbox">{{$t('login.agree')}}
                        </label>
                        <router-link class="gradient" to="/agreement">《{{$t('common.agreement')}}》</router-link>
                    </div>
                    
                    <button @click="login" class="btn login">{{$t('common.login')}}</button>

                    <router-link to="/register" class="btn btn-o">
                    <span class="gradient">{{$t('login.toRegister')}}</span></router-link>
                </div>
                <div class="login_footer wow animated fadeInUp">
                    <p><span>System version {{version}} </span> @ 2022 LLKKZ.NET ALL RIGHTS RESERVED</p>
                </div>
            </div>
            <div class="icon">
                <img src="@/assets/images/official/login/icon.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import {Message} from 'element-ui'
import { WOW } from 'wowjs'
import { mapGetters } from 'vuex';
export default {
    data(){
        return{
            account:'',
            password:'',
            checked:true,
        }
    },
    computed:{
        ...mapGetters(['version'])
    },
    created(){
        // 初始化安全登录的账户、邮箱
        this.$store.dispatch('user/setSafeAccount','')
        this.$store.dispatch('user/setSafeEmail','')
    },
    mounted(){
        this.$nextTick(()=>{
            this.wow();
        })
    },
    methods:{
        wow(){
            // if( document.querySelectorAll('.wow').length ){
            //     if (! (/msie [6|7|8|9]/i.test(navigator.userAgent))) {
            var wow = new WOW({
                boxClass:     'wow',      // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset:       0,          // distance to the element when triggering the animation (default is 0)
                mobile:       true,       // trigger animations on mobile devices (default is true)
                live:         true       // act on asynchronously loaded content (default is true)
            });
            this.$nextTick(() => {
                wow.init();
            });
            //     };
            // };
        },
        jump(path){
            this.$router.push({
                path
            })
        },
        login(){
            // console.log('登录')
            if(!this.account){
                Message({
                    message:this.$t('tip.usernameEmpty')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(!this.password){
                Message({
                    message:this.$t('tip.pwdEmpty1')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else if(!this.checked){
                Message({
                    message:this.$t('tip.check')+'！',
                    type: 'error',
                    duration: 3 * 1000
                });
            }else{
                this.$store.dispatch('user/setSafeAccount',this.account)
                this.$store.dispatch('user/login', {
                    account:this.account,
                    password:this.password,
                }).then(res=>{
                    if(this.$route.query.redirect){
                        this.$router.push({
                            path:this.$route.query.redirect
                        })
                    }else{
                        this.$router.push({
                            // path:'/account'
                            path:'/stocks'
                        })
                    }
                })
            }
        },
    },
}
</script>
<style scoped="scoped" lang="stylus">
	@import './login.styl';
	@import '~@/assets/style/frames.styl';
</style>